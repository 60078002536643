import React from "react"
import {Helmet} from "react-helmet"
import {Link, withPrefix, graphql} from "gatsby"
import {formatDistanceToNow} from "date-fns"
import {pl} from "date-fns/locale"
import Layout from "@layout/layout"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

const isEn = false

export default ({data}) => {
  const post = data.markdownRemark

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={post.frontmatter.metaTitle}
        description={post.frontmatter.metaDescription}
      />
      <Helmet
        meta={[
          {
            property: `og:title`,
            content: post.frontmatter.metaTitle.trim()
          },
          {
            property: `og:url`,
            content: `https://sasafoundation.com/${post.frontmatter.slug}`
          },
          {
            property: `og:image`,
            content: withPrefix(post.frontmatter.thumbnail)
          },
        ]}
      >
        <script type={'application/ld+json'}>
        {`
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://sasafoundation.com${post.frontmatter.slug}"
          },
          "headline": "${post.frontmatter.title.trim()}",
          "image": "${withPrefix(post.frontmatter.thumbnail)}",
          "datePublished": "${post.frontmatter.date}",
          "publisher": {
            "name": "SASA fundacja",
            "@type": "Organization",
            "logo": {
              "@type": "ImageObject",
              "url": "https://sasafoundation.com${withPrefix('images/logo.svg')}"
            }
          },
          "isAccessibleForFree": "True",
          "author": {
            "@type": "Person",
            "name": "${post.frontmatter.author}"
          }
        }
        `}
        </script>
      </Helmet>

      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <section
          className="news-cover display-table"
          style={{
            backgroundImage: `url(${withPrefix(post.frontmatter.thumbnail)})`,
          }}
        >
          <div
            className="news-cover-inner display-table-cell wow fadeInUp"
            data-wow-duration="1.5s"
            style={{visibility: "hidden"}}
          >
            <div className="row">
              <div className="large-6 large-push-2 medium-12 small-12 columns">
                <h1
                  className="white margin-bottom10"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.title}}
                ></h1>
                <p>
                  <strong className="big white">
                    {post.frontmatter.author}
                  </strong>
                  <i className="fa fa-clock-o"/>{" "}
                  {formatDistanceToNow(new Date(post.frontmatter.date), {
                    locale: pl,
                  })}
                </p>
              </div>
            </div>
          </div>
        </section>
        <article className="news-main section padding">
          <div className="row">
            <div
              className="large-2 medium-12 small-12 columns text-center sticky-container"
              data-sticky-container
            >
              <div
                className="social-sticky sticky is-anchored is-at-top"
                data-sticky="4by3qh-sticky"
                data-margin-top={5}
                data-sticky-on="large"
                data-stick-to="top"
                data-anchor="newsheight"
                data-resize="ro2x8p-sticky"
                data-events="resize"
              >
                <div className="social-trends">
                  <small>Social trends</small>
                  <a
                    href="https://www.facebook.com/sasafoundation/"
                    className="social-box"
                  >
                    <i className="fa fa-facebook"/>
                  </a>
                  <a
                    href="https://twitter.com/sasa_foundation"
                    className="social-box"
                  >
                    <i className="fa fa-twitter"/>
                  </a>
                  <a href="https://plus.google.com" className="social-box">
                    <i className="fa fa-google-plus"/>
                  </a>
                  <a
                    href="https://www.linkedin.com/company-beta/17908816/"
                    className="social-box border-bottom"
                  >
                    <i className="fa fa-linkedin"/>
                  </a>
                  <div
                    className="fb-like fb_iframe_widget"
                    data-href="https://www.facebook.com/sasafoundation/?fref=ts"
                    data-layout="button"
                    data-action="like"
                    data-show-faces="false"
                    data-share="false"
                    fb-xfbml-state="rendered"
                    fb-iframe-plugin-query="action=like&app_id=354696121251703&container_width=170&href=https%3A%2F%2Fwww.facebook.com%2Fsasafoundation%2F%3Ffref%3Dts&layout=button&locale=pl_PL&sdk=joey&share=false&show_faces=false"
                  >
                    <span
                      style={{
                        verticalAlign: "bottom",
                        width: "112px",
                        height: "28px",
                      }}
                    >
                      <iframe
                        name="f1a4ef1ddbbd08"
                        width="1000px"
                        height="1000px"
                        data-testid="fb:like Facebook Social Plugin"
                        title="fb:like Facebook Social Plugin"
                        frameBorder={0}
                        allowTransparency="true"
                        allowFullScreen="true"
                        scrolling="no"
                        allow="encrypted-media"
                        src="https://www.facebook.com/v2.6/plugins/like.php?action=like&app_id=354696121251703&channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df228e22fdb9e03%26domain%3Dsasafoundation.com%26origin%3Dhttps%253A%252F%252Fsasafoundation.com%252Ff2dc714c83cb958%26relation%3Dparent.parent&container_width=170&href=https%3A%2F%2Fwww.facebook.com%2Fsasafoundation%2F%3Ffref%3Dts&layout=button&locale=pl_PL&sdk=joey&share=false&show_faces=false"
                        style={{
                          border: "none",
                          visibility: "visible",
                          width: "112px",
                          height: "28px",
                        }}
                        className
                      />
                    </span>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div className="large-6 medium-12 small-12 columns" id="newsheight">
              <div
                className="news-text wow fadeInUp"
                data-wow-duration="1.5s"
                style={{visibility: "hidden"}}
                dangerouslySetInnerHTML={{__html: post.html}}
              ></div>
            </div>
            <div className="large-3 large-push-1 medium-12 small-12 columns">
              <aside>
                <div className="nsl-frame side">
                  <h6>{isEn ? 'Stay updated' : 'Bądź na bieżąco'} </h6>
                  <p>{isEn ? `Join newsletter. We are not sending spam.` : 'Zapisz się do newslettera. My nie wysyłamy spamu.'}</p>
                  <form
                    action="//sasafoundation.us10.list-manage.com/subscribe/post?u=dddf51132b3e6f99bfadf3e42&id=144f9c49af"
                    method="post"
                    name="mc-embedded-subscribe-form"
                    target="_blank"
                    id="sidebar_newsletter-side"
                  >
                    <div className="row expanded collapse">
                      <div className="large-12 columns">
                        <span className="input input--madoka">
                          <input
                            className="input__field input__field--madoka email"
                            type="text"
                            defaultValue={""}
                            name="EMAIL"
                            id="wpisz-maila-side"
                          />
                          <label
                            className="input__label input__label--madoka"
                            htmlFor="wpisz-maila-side"
                          >
                            <svg
                              className="graphic graphic--madoka"
                              width="100%"
                              height="100%"
                              viewBox="0 0 404 77"
                              preserveAspectRatio="none"
                            >
                              <path d="m0,0l404,0l0,77l-404,0l0,-77z"/>
                            </svg>
                            <span className="input__label-content input__label-content--madoka">
                              {isEn ? 'Join our newest newsletter' : 'Zapisz się do naszego newslettera'}
                            </span>
                          </label>
                          <input
                            type="submit"
                            name="subscribe"
                            className="send"
                            defaultValue
                            style={{display: "none"}}
                          />
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </aside>
            </div>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query BlogQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date
                slug
                author
                excerpt
                thumbnail
                tag
                metaTitle
                metaDescription
            }
        }
    }
`
